import React from 'react';
import { graphql }  from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default ({ data }) => {

  const post = data.markdownRemark;
  const { site } = data;

  let { fields } = post;
  let { slug } = fields;

  const canonical = site.siteMetadata.siteUrl + slug;

  return (
    <Layout>
      <div className="pb4-ns">

        <Helmet>
          <title>{post.frontmatter.title} | SwitchSmith</title>
          <link rel="canonical" href={canonical} />
        </Helmet>

        <div className="row pt3">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
            <h1>{post.frontmatter.title}</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>

      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {

    site {
      siteMetadata {
        title
        description
        siteUrl
        site_url: siteUrl
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`